.SearchBox {
  width: 100%;
  height: 100%;
  display: flex;
}
.SearchBox__input,
.SearchBox__iconSection {
  height: 100%;
}
.SearchBox__input {
  flex: 1;
  background: transparent;
  outline: none;
  font-size: 18px;
}
.SearchBox__iconSection {
  width: 54px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #525e8b;
  position: absolute;
  right: -3px;
  top: -1px;
  bottom: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.SearchBox__icon {
  width: 26px;
  height: 26px;
}
