.Button {
    min-width: 140px;
    height: 64px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 3px solid #515D89;
    text-align: center;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
}
.Button_style_one {
    background-color: #515D89;
    color: white;
}
.Button_style_two {
    background-color: #fff;
    color: #515D89;
}
.Button_deactivate {
    pointer-events: none;
    background-color: #969696;
    border: none !important;
}
.Button:hover {
    box-shadow: 5px 5px 5px #dcdcdc;
    border: 4px solid #525E8B;
}