.ReservationTable__row_teamPortal {
  min-height: 51px;
  cursor: pointer;
}
.ReservationTable__row_teamPortal > * {
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  align-items: center;
}
.ReservationRow_status {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.ReservationTable__img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.ReservationTable__selectedRow {
  /* padding-right: 18%; */
  background-color: #f6f6f8;
}
.ReservationTable__selectedRow > .ReservationTable_cell:first-child {
  border-top-left-radius: 5px;
}
.ReservationTable__selectedRow > .ReservationTable_cell:last-child {
  border-top-right-radius: 5px;
}
.ReservationTable__selectedRow .ReservationTable_cell {
  background-color: #fff;
  border-bottom: 3px solid #525e8b;
}
@media screen and (min-width: 228px) and (max-width: 1200px) {
  .ReservationTable__selectedRow {
    padding-right: 0%;
    background-color: #f6f6f8;
  }
}