.Availability {
  position: absolute;
  display: flex;
  width: 809px;
  min-height: 150px;
  left: -3px;
  top: 101%;
  border: 3px solid #525e8b;
  background-color: #f6f6f8;
  z-index: 100;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 20px;
  padding-top: 11px;
  padding-bottom: 10px;
  cursor: default;
}
.Availability:before {
  padding: 0;
  margin: 0;
  content: "";
  width: 189px;
  height: 3.5px;
  background-color: #f2f2f4;
  position: absolute;
  z-index: 2;
  left: 0;
  top: -3px;
}
.Availability__select {
  height: 100%;
}
.Availability__select_label {
  padding-left: 0;
  padding-bottom: 6px;
}
.Availability__time_select_button {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 17px;
  grid-column-gap: 14px;
}
.Availability__restaurant_select_button {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 8px;
}

.Availability > * + * {
  margin-left: 40px;
}

@media screen and (min-width: 1300px) and (max-width: 1437px) {
  .Availability {
    width: 609px;
  }
  .Availability__time_select_button {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 228px) and (max-width: 1258px) {
  .Availability {
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    justify-content: space-between;
    width: 65vw;
  }
  .Availability__select {
    width: 100%;
  }
  .Availability > * + * {
    margin-top: 25px;
    margin-left: 0;
  }
  .Availability__time_select_button {
    grid-template-columns: repeat(auto-fill, 100px);
  }
}
@media screen and (min-width: 913px) and (max-width: 1258px) {
  .Availability {
    width: 55vw;
  }
}
@media screen and (min-width: 900px) and (max-width: 1020px) {
  .Availability {
    width: 45vw;
  }
}

@media screen and (min-width: 228px) and (max-width: 775px) {
  .Availability__time_select_button {
    grid-template-columns: repeat(auto-fill, 100px);
    grid-row-gap: 10px;
    grid-column-gap: 0px;
    justify-content: center;
    padding-top: 10px;
  }
}
@media screen and (min-width: 228px) and (max-width: 900px) {
  .Availability_Odd {
    right: -3px;
    left: auto;
  }
  .Availability_Odd:before {
    left: auto;
    right: 0px;
  }
}
@media screen and (min-width: 228px) and (max-width: 685px) {
  .Availability {
    padding-left: 15px;
    padding-right: 15px;
    width: 75vw;
  }
}
