.SelectAndSearchDropDown_input {
  width: 80px;
  height: 30px;
  margin-bottom: 20px;
}
.SelectAndSearchDropDown * {
  padding: 0 !important;
  z-index: 1;
}

.SelectAndSearchDropDown input {
  padding-left: 10px !important;
}
@media screen and (min-width: 228px) and (max-width: 1180px) {
  .SelectAndSearchDropDown button:first-child {
    display: none !important;
  }
}
