.CheckBoxes {
  width: 100px;
  height: 412px;
  overflow-y: hidden;
  margin-top: 40px;
  padding-left: 15px;
}
.CheckBoxes__checkBox_row {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
}
.CheckBoxes__checkBox {
  width: 28px;
  height: 28px;
  max-height: 28px;
  border-radius: 3px;
  border: 2px solid #707070;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CheckBoxes__icon {
  width: 100%;
  height: 100%;
}
.CheckBoxes::-webkit-scrollbar {
  display: none;
}
