.TableNumber {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.TableNumber__icon {
  width: 30px;
  height: 25px;
  cursor: pointer;
}
.TableNumber > div {
  flex: 1;
}
