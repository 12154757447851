.AlertBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: fixed;
    z-index: 100;
    width: 600px;
    height: auto;
    min-height: 250px;
    border: 2px solid #525e8b;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 2px 5px 15px gray;
}
.alertMsg {
    width: 100%;
    text-align: center;
    padding-bottom: 25px;
}