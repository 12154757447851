.GroupDisplay {
  display: flex;
}
.GroupDisplay > * {
  border-bottom: 2px solid #a0a0a0;
  padding-bottom: 13px;
  padding-top: 13px;
}
.GroupDisplay__checkAllBtn {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.GroupDisplay__totalNumber {
  display: flex;
  font-size: 20px;
  padding-right: 20px;
}
.GroupDisplay > *:first-child {
  margin-left: 20px;
  padding-left: 0px !important;
}
@media screen and (min-width: 280px) and (max-width: 1400px) {
    .GroupDisplay > *:nth-child(9){
        width: 80px !important;
    }
    .GroupDisplay > *:nth-child(8){
        width: 95px !important;
    }
    .GroupDisplay > *:nth-child(6){
        width: 65px !important;
    }
}

@media screen and (min-width: 280px) and (max-width: 1250px) {
    .GroupDisplay > *:nth-child(2){
        padding-left: 10px!important;
        padding-right: 10px!important;
    }
}
