.SelectCard {
  width: 272px;
  height: auto;
  height: fit-content;
  background-color: #f6f6f8;
  border: #525e8b 3px solid;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 15px;
  color: #707070;
  cursor: pointer;
  font-size: 18px;
}
/* .SelectCard *, .SelectCard *::after, .SelectCard *::before {
  border: none !important;
} */
.SelectCard button > svg {
  display: none;
}