@font-face {
  font-family: "Open Sans";
  font-style: normal;
  src: url("../assets/fonts/static/OpenSans/OpenSansRegular.ttf");
}
@font-face {
  font-family: "Helvetica Neue";
  font-style: normal;
  src: url("../assets/fonts/helveticaneue.ttf");
}
@font-face {
  font-family: "Georgia_bold";
  font-style: normal;
  src: url("../assets/fonts/georgia_bold.ttf");
}
*,
*:after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
ul {
  list-style-type: none;
}
input {
  outline: none;
  border: none;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
