.SelectedRowCard {
  width: 100%;
  min-height: 228px;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding-left: 32px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media screen and (min-width: 228px) and (max-width: 685px) {
  .Itinerary {
    padding-left: 10px;
    padding-right: 10px;
  }
}
