.FilterSection {
  width: 100%;
  height: auto;
}
.FilterSection__Filtes {
  display: flex;
}
.FilterSection__DropDowns {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 60px;
  padding-right: 60px;
  gap: 15px;
}
.FilterSection_FilterDisplay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 10px;
}
.FilterSection___Added_Filters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
.FilterSection__ResetBTN {
  text-decoration: underline;
  color: #525e8b;
  font-weight: bold;
  cursor: pointer;
}
.FilterSection__DropDowns > * {
  margin-bottom: 20px;
}
.FilterSection___Added_Filters > * + * {
  margin-left: 17px;
}

.temporaryElement {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #525e8b;  
  font-weight: bold;
  font-size: 20px;
}
@media screen and (min-width: 280px) and (max-width: 1400px) {
  .FilterSection__Filtes {
    width: 100%;
    flex-wrap: wrap;
  }
  .FilterSection__DropDowns {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .FilterSection__DropDowns > * + * {
    margin-left: 0px;
    margin-right: 0px;
  }
}
