.AddedFilter {
  display: flex;
  width: fit-content;
  height: 36px;
  align-items: center;
  background-color: #f6f6f8;
  padding-left: 10px;
  padding-right: 15px;
  cursor: pointer;
  border: 2px solid #525e8b;
  border-radius: 3px;
  margin-bottom: 15px;
  text-transform: capitalize;
}
.AddedFilter_cancel {
  color: #525e8b;
  font-size: 0.9rem;
  padding-right: 5px;
  text-shadow: 1px 0 #525e8b;
  letter-spacing: 5px;
  font-weight: bold;
}
