.ArrivalsDate {
  color: #000;
  font-weight: bold;
  z-index: 1;
  display: flex;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ArrivalsDate > div > div * {
  padding: 0 !important;
  border: none !important;
}
.ArrivalsDate_modifyLink {
  text-decoration: underline;
  cursor: pointer;
  padding-left: 20px;
  color: #525e8b;
}
.ArrivalsDate_datePicker {
}
