.ItineraryDropDowns__label_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}
.ItineraryDropDowns__Icon {
  width: 16px;
  height: 16px;
}
.ItineraryDropDowns__Label,
.ItineraryDropDowns__select_label {
  font-size: 18px;
  color: #525e8b;
  font-weight: bold;
  padding-left: 15px;
}
.ItineraryDropDowns__selectionDisplay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 30px;
}
.ItineraryDropDowns__selectedText {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}
.ItineraryDropDowns_icon {
  width: 21px;
  height: 21px;
  position: absolute;
  right: 0px;
  transition: transform 0.5s ease-in-out;
}
.ItineraryDropDowns_icon_rotate {
  transform: rotateZ(180deg);
}
.ItineraryDropDowns__selectBoxes > * + * {
  margin-top: 9px;
}

.ItineraryDropDowns__select {
  height: 100%;
}
.ItineraryDropDowns__select_label {
  padding-left: 0;
  padding-bottom: 6px;
}
.ItineraryDropDowns__time_select_button {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 17px;
  grid-column-gap: 14px;
}
.ItineraryDropDowns__restaurant_select_button {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 8px;
}

