.ReservationTable__row_arrivals > * {
  padding-top: 13px;
  padding-bottom: 7px;
}
.ReservationTable__row_expand > div {
  background-color: #fff;
  align-items: flex-start !important;
}
.Reservation_row_detailsIcon,
.Reservation_row_groupExpandIcon {
  width: 16px;
  height: 13px;
  margin-right: 8px;
  margin-bottom: 5px;
}
.Reservation_row_groupExpandIcon {
  transform: rotateZ(153deg);
}
.Reservation_row_firstCell {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.Reservation_row_visible {
  width: 100%;
  display: flex;
  align-items: center;
}
.Reservation_row_details {
  height: 0;
  opacity: 0;
  padding-left: 24px;
  padding-right: 15px;
  padding-left: 35px;
  white-space: pre-line;
  color: #000000;
}
.ReservationTable__row_expand .Reservation_row_groupExpandIcon {
  transform: rotateZ(242deg);
}
.ReservationTable__row_expand .Reservation_row_details {
  width: 100%;
  height: fit-content;
  opacity: 1;
  padding-top: 10px;
}
.Reservation_row_group_check {
  display: flex;
  height: fit-content;
  justify-content: flex-end;
  position: absolute;
  padding-right: 25px;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 10px;
  padding-top: 10px;
}
.ReservationTable__row_expansion {
  width: 100%;
}
.Reservation_row_details__specialRequests {
  padding-left: 25px;
}
.ReservationTable__row_expansion > * {
  border-right: none;
}