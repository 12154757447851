.ToggleButton {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ToggleButton__label {
  font-size: 20px;
  color: #525e8b;
  font-weight: bold;
  padding-bottom: 5px;
  font-family: "Georgia_bold";
  padding-bottom: 20px;
}
.ToggleButton__btn {
  width: 120px;
  height: 45px;
  border-radius: 20px;
  border: 1px solid #525e8b;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  transition: 0.5s all ease-in-out;
}
.ToggleButton__txt {
  font-size: 14px;
  color: #fff;
}
.ToggleButton__action {
  position: absolute;
  width: 45px;
  border-radius: 50%;
  background-color: #000;
  top: 0;
  left: -10px;
  bottom: 0;
  transition: 0.5s all ease-in-out;
}
.ToggleButton__ON {
  background-color: #2F5C27;
}
.ToggleButton__OFF {
  background-color: #AC1B1B;
}
.ToggleButton__ON > .ToggleButton__action {
  left: calc(100% - 43px);
}
.ToggleButton__OFF > .ToggleButton__action {
  left: -2px;
}
