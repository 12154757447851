table {
  width: 100%;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-spacing:0;
}

td,
th {
  border-top: 1px solid black;
  border-left: 1px solid black;
}