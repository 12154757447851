.LoginLinkPage {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.LoginLinkPage__Box {
    width: 730px;
    height: 460px;
    background-color: #F2F2F4;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.LoginLinkPage__Logo {
    width: 300px;
    height: 40px;
}
.LoginLinkPage__Desc {
    width: 60%;
    line-height: 28px;
}