.PageHeadLine {
  width: 100%;
  padding-top: 30px;
}
.PageHeadLine_title {
  width: 100%;
  display: flex;
  color: #525e8b;
  font-family: "Gergoia_bold";
  justify-content: center;
  align-items: center;
}
.PageHeadLine__hotel_title {
  font-weight: bold;
  font-size: 18px;
  margin-left: 5px;
}
