.Home {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3.5rem;
}
.Home__Welcome,
.Home__Navigate {
  width: 72.916666666667vw;
}
.Home__Welcome > * {
  margin-bottom: 10px;
}
.Home__Navigate {
  height: 402px;
  background-color: #f2f2f4;
  padding-left: 45px;
  padding-top: 39px;
  margin-top: 15px;
}
.Home__Buttons {
  margin-top: 54px;
}
.Home__Buttons > * + * {
  margin-left: 80px;
}
@media screen and (min-width: 600px) and (max-width: 1275px) {
  .Home__Welcome,
  .Home__Navigate {
    width: 95vw;
  }
}
@media screen and (min-width: 600px) and (max-width: 970px) {
  .Home__Buttons {
    display: grid;
    grid-template-columns: repeat(auto-fill, 250px);
    grid-gap: 20px;
  }
  .Home__Buttons > * + * {
    margin-left: 0px;
  }
  .Home__Navigate {
    padding-left: 15px;
  }
}
