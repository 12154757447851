.ItinerarySelectButton {
    height: 33px;
    border: 2px solid #525E8B;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    cursor: pointer;
    transition: 0.4s ease-in-out;
}
.ItinerarySelectButtonHighlited {
    background-color: #525E8B;
    color: #fff;
}
.ItinerarySelectButtonNotAvailable {
    opacity: 0.4;
    pointer-events: none;
}
.ItinerarySelectButtonHighlited .TimeCard__availability {
    color: #fff;
    font-weight: normal;
}
.TimeCard__availability {
    font-size: 14px;
    color: #000;
    font-weight: bold;
    margin-left: 5px;
}