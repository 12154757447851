.ReservationsPage {
    padding-left: 0px;
    padding-right:0px;
    padding-top: 25px;
}
.ReservationsPage__Welcome{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* @media screen and (min-width: 1765px) and (max-width: 1900px) {
    .ReservationsPage {
        padding-left: 260px;
        padding-right: 260px;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1764px) {
    .ReservationsPage {
        padding-left: 100px;
        padding-right: 100px;
    }
}
@media screen and (min-width: 901px) and (max-width: 1300px) {
    .ReservationsPage {
        padding-left: 10px;
        padding-right: 10px;
    }
}
@media screen and (min-width: 501px) and (max-width: 900px){
    .ReservationsPage {
        padding-left: 25px;
        padding-right: 25px;
    }
}
@media screen and (min-width: 280px) and (max-width: 500px){
    .ReservationsPage {
        padding-left: 0px;
        padding-right: 0px;
    }
}
@media screen and (min-width: 280px) and (max-width: 858px){
    .ReservationsPage {
        padding-top: 0px;
    }
} */