.Autoallocate_title {
  color: #fff;
}

.Autoallocate_icon {
  width: 15px;
  height: 15px;
  margin-left: 10px;
}

.popupBoxWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  position: fixed;
  z-index: 100;
  width: 95%;
  max-width: 95%;
  height: 95%;
  min-height: 250px;
  border: 2px solid #525e8b;
  border-radius: 5px;
  padding: 35px;
  /* overflow: auto; */
  top: 30px;
}

.popupBoxWrapper>.closeButtons {
  width: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: right;
  position: absolute;
  top: 20px;
  right: 20px;
}

.bookingList {
  margin-top: 25px;
  /* max-height: 250px; */
  /* height: 360px; */
}

.bookingList>*+* {
  margin-top: 15px;
  width: 100%;
}

.bookingPreviewItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.singleBooking {
  list-style-type: none;
  width: 95%;
  min-height: 45px;
  border-radius: 5px;
  background-color: #42485d;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 5px;
}

.singleBooking * {
  font-size: 16px;
  color: #fff;
}

.singleBooking *:first-child {
  width: 17%;
}

.singleBooking *:nth-child(2) {
  color: #fff;
  width: 17%;
}

.singleBooking *:nth-child(3) {
  width: 17%;
}

.singleBooking *:nth-child(4) {
  width: 17%;
}

.singleBooking *:last-child {
  width: 50%;
  padding-top: 15px;
  padding-bottom: 15px;
}

/* .progressbar {
  position: relative;
  width: 170px;
  height: 170px;
  margin: 1em;
  transform: rotate(-90deg);
}

.progressbar__svg {
  position: relative;
  width: 100%;
  height: 100%;
}

.progressbar__svgCircle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 10;
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
  stroke: #525e8b;
  stroke-linecap: round;
  transform: translate(5px, 5px);
}

.progressbar__text {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0.25em 0.5em;
  color: #525e8b;
  border-radius: 0.25em;
  transform: translate(-50%, -50%) rotate(90deg);
} */