.UserWelcomeModal {
  width: 100%;
  height: 36px;
  background-color: #f2f2f4;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  padding-left: 65px;
  align-items: center;
}
.UserWelcomeModal_arrival {
  position: relative;
}
.UserWelcomeModal__title {
  padding-right: 40px;
}
.UserWelcomeModal__logOut {
  color: #525E8B;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
.UserWelcomeModal__logOut:hover {
  text-decoration: underline;
}
.UserWelcomeModal__userInfo {
  display: flex;
  z-index: 1;
}
.UserWelcomeModal__hotel {
  color: #525E8B;
}
.UserWelcomeModal__hotel {
  font-weight: bolder;
  padding-right: 20px;
  font-size: 1.2rem;
}
.UserWelcomeModal_arrival > .UserWelcomeModal__hotel {
  position: absolute;
  text-align: center;
  z-index: 0;
  left: 0;
  right: 0;
}
.UserWelcomeModal__backIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  z-index: 3;
  left: 20px;
}