.ReservationTable__wrapper {
  width: 100%;
  display: flex;
}
.ReservationTable {
  border-collapse: collapse;
  width: 100%;
}
.ReservationTable__body,
.ReservationTable__head,
span {
  display: flex;
}
.ReservationTable__head {
  min-height: 40px;
  background-color: #525e8b;
}
.ReservationTable__body,
.ReservationTable__loadingState {
  height: 50vh;
}
.ReservationTable__body {
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  width: 100%;
}
.ReservationTable__row {
  display: flex;
  background-color: #f6f6f8;
}
.ReservationTable__loadingState,
.ReservationTable__noContent {
  width: 100%;
  background-color: #f6f6f8;
}
.ReservationTable__noContent {
  height: 100%;
}
.ReservationTable__noContent {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #ac1b1b;
}
.ReservationTable__icon {
  margin-left: 5px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
}
.ReservationTable_icon__rotate {
  transform: rotate(180deg);
}

.ReservationTable__head > * {
  font-size: 18px;
  text-transform: capitalize;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ReservationTable__row > * {
  border-right: 1px solid #707070;
  border-bottom: 1px solid #707070;
  transition: max-height 0.7s ease-in-out;
}
.ReservationTable_cell {
  padding-left: 10px;
  display: flex;
  word-break: break-word;
}

.ReservationTable__portal .ReservationTable_cell:last-child,
.ReservationTable__portal .ReservationTable_cell:last-child {
  flex: 1;
  min-width: 107px;
}
.ReservationTable__arrivals .ReservationTable_cell:first-child,
.ReservationTable__arrivals .ReservationTable_cell:first-child {
  flex: 1;
}
.ReservationTable__arrivals .ReservationTable_cell:last-child,
.ReservationTable__arrivals .ReservationTable_cell:last-child {
  border-right: none;
}
.ReservationTable__arrivals .ReservationTable__head {
  padding-right: 20px;
}

/* dining */
.ReservationTable__dining .ReservationTable_cell:first-child,
.ReservationTable__dining .ReservationTable_cell:first-child {
  flex: 1;
}
.ReservationTable__dining .ReservationTable_cell:last-child,
.ReservationTable__dining .ReservationTable_cell:last-child {
  border-right: none;
}
.ReservationTable__dining .ReservationTable__head {
  padding-right: 20px;
}
/* dining */

.ReservationTable__body::-webkit-scrollbar {
  width: 20px;
  position: absolute;
  height: 200px;
}
.ReservationTable__body::-webkit-scrollbar-track {
  background: #f6f6f8;
  border-radius: 10px;
}
.ReservationTable__body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #525e8b;
}

.ReservationTable__dining .ReservationTable_cell:nth-child(1),
.ReservationTable__arrivals .ReservationTable_cell:nth-child(1) {
  min-width: 150px !important;
}

@media screen and (min-width: 280px) and (max-width: 1400px) {
  .ReservationTable__head .ReservationTable_cell {
    font-size: 18px;
  }
  .ReservationTable__body {
    height: 697px;
  }
}
@media screen and (min-height: 1800px) and (max-height: 1920px) {
  .ReservationTable__body {
    height: 1255px;
  }
}
@media screen and (min-width: 228px) and (max-width: 1775px) {
  .ReservationTable__arrivals .ReservationTable_cell:nth-child(2) {
    width: 190px !important;
  }
  .ReservationTable__arrivals .ReservationTable_cell:nth-child(7) {
    text-align: center;
  }
}
@media screen and (min-width: 228px) and (max-width: 925px) {
  .ReservationTable__head .ReservationTable_cell {
    font-size: 16px;
  }
}

/* dining */
@media screen and (min-width: 228px) and (max-width: 1775px) {
  .ReservationTable__dining .ReservationTable_cell:nth-child(9) {
    text-align: center;
  }
  .ReservationTable__dining .ReservationTable_cell:nth-child(3) {
    padding-left: 0px !important;
    text-align: center;
  }
}
@media screen and (min-width: 1390px) and (max-width: 1775px) {
  .ReservationTable__dining .ReservationTable_cell:nth-child(2) {
    width: 150px !important;
    justify-content: center !important;
    padding-left: 0 !important;
  }
  .ReservationTable__dining .ReservationTable_cell:nth-child(3) {
    width: 220px !important;
    justify-content: center !important;
    padding-left: 0 !important;
  }
}

@media screen and (min-width: 1260px) and (max-width: 1390px) {
  .ReservationTable__dining .ReservationTable_cell:nth-child(2) {
    width: 100px !important;
    text-align: center;
  }
}
@media screen and (min-width: 1180px) and (max-width: 1260px) {
  .ReservationTable__dining .ReservationTable_cell:nth-child(10) {
    width: 70px !important;
  }
  .ReservationTable__head .ReservationTable_cell:nth-child(10) {
    text-align: center;
  }
}

@media screen and (min-width: 860px) and (max-width: 1180px) {
  .ReservationTable__dining .ReservationTable_cell:nth-child(10) {
    width: 60px !important;
    text-align: center;
  }
  .ReservationTable__dining .ReservationTable_cell:nth-child(9) {
    width: 68px !important;
    text-align: center;
  }
  .ReservationTable__dining .ReservationTable_cell:nth-child(8) {
    text-align: center;
  }

  .ReservationTable__dining .ReservationTable_cell:nth-child(6) {
    text-align: center;
    justify-content: center !important;
  }
  .ReservationTable__dining .ReservationTable_cell:nth-child(3) {
    width: 90px !important;
    text-align: center;
  }

  .ReservationTable__dining .ReservationTable_cell:nth-child(2) {
    width: 110px !important;
    text-align: center;
  }
}

@media screen and (min-width: 228px) and (max-width: 860px) {
  .ReservationTable__dining .ReservationTable_cell {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }
  .ReservationTable__dining .ReservationTable_cell:first-child {
    padding-left: 10px !important;
    min-width: 100px !important;
  }
  .ReservationTable__dining .ReservationTable_cell:nth-child(2) {
    width: 90px !important;
  }
  .ReservationTable__dining .ReservationTable_cell:nth-child(3) {
    width: 85px !important;
  }
  .ReservationTable__dining .ReservationTable_cell:nth-child(6) {
    min-width: fit-content !important;
  }
  .ReservationTable__dining .ReservationTable_cell:nth-child(8) {
    width: 81px !important;
    text-align: center;
  }
  .ReservationTable__dining .ReservationTable_cell:nth-child(10) {
    width: 65px !important;
    text-align: center;
  }
}
