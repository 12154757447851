.Header_Base {
  width: 100%;
  height: 100px;
  background-color: #525e8b;
  border: 1px solid #707070;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Header_Login {
  height: 100px;
}
.Header_Auth {
  height: 67px;
}
.Header_Login > *:last-child,
.Header_Auth > *:first-child {
  display: none;
}
.Header_After_Auth {
  width: 100%;
}
._Header_top {
  width: 100%;
  height: 67px;
  background-color: #525e8b;
  display: flex;
  justify-content: center;
  align-items: center;
}
