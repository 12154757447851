.SpecialRequests {
    width: 100%;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-left: 0px;
}
.SpecialRequests__stored,
.SpecialRequests__content {
    width: 80%;
}
.SpecialRequests__content {
    display: flex;
    align-items: center;
    padding-top: 25px;
}
.SpecialRequests__stored {
    padding-left: 15px;
}
.SpecialRequests__comments {
    margin-left: 35px;
    display: block;
}
.SpecialRequests__input {
    width: 40%;
    height: 101px;
    padding: 15px;
    border: 1px solid;
}