.ButtonSection {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 25px;
    padding-bottom: 10px;
}
.ButtonSection > * + * {
    margin-left: 68px;
}