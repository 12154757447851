.Itinerary__selections{
  display: flex;
  flex-wrap: wrap;
}
.Itinerary__dates {
  padding-top: 45px;
  padding-bottom: 15px;
  color: #525e8b;
}
.Itinerary__selectBoxes {
  display: flex;
  padding-left: 55px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.Itinerary__date_item {
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Itinerary__dates > * + * {
  margin-top: 9px;
}
.Itinerary__selectBoxes > * + * {
  margin-left: 60px;
}
.Itinerary_autoAllocate {
  /* height: 100%; */
  padding-left: 60px;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 228px) and (max-width: 950px) {
   .Itinerary_autoAllocate {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    margin-top: 20px;
  }
}
